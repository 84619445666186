import React from 'react'
import '../style/LeaderBoard.css'

export default function leaderboard() {
    return (
        <div className="row">
            <div className="col-12">
                <h1>Leader Board</h1>
            </div>
        </div>
    )
}

