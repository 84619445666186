import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import AppBarActions from "./AppBarActions";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },

    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    inputRoot: {
        color: 'inherit',
    },

    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function ChallengeBar(props) {
    const classes = useStyles();
    var bannerTitle = "";
    if(props.name.includes("Challenge") && !props.name.includes("Set") && props.name.length <= 11){
        bannerTitle = "Current Challenge: " + props.name
    }else{
        bannerTitle = props.name
    }
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <div className="container">
                    {/* <Toolbar> */}
                        {/* <Typography className={classes.title} variant="h6" noWrap> */}
                            {/* {bannerTitle} */}
                            {/* <img src="logo.svg" alt="logo"/> */}
                        {/* </Typography> */}

                        {/* the bellow is for credential login and survey button */}
                        <AppBarActions/>
                    {/* </Toolbar> */}
                </div>
            </AppBar>
        </div>
    );
}
